<template>
  <div class="NavigationFooter">
    <div>
      <p>
        <span>Next</span>
        <a
          :href="navigation.href"
          @click.prevent="onClick"
          @mouseenter="onMouseEnter"
          @touchstart="onMouseEnter"
          :class="{ 'is-animating-hover': isHoverAnimationRunning }"
        >
          <strong>{{ navigation.title }}</strong>
        </a>
      </p>
    </div>
  </div>
</template>

<script>

import routes from '@/data/routes'
import { getNextPieceBySlug } from '@/data/collection'

export default {
  name: 'NavigationFooter',
  props: {
    slug: {
      type: String,
      required: true
    },
    onNavigate: {
      type: Function
    }
  },
  components: {},
  data() {
    return {
      isHoverAnimationRunning: false,
      hoverAnimationTimer: null
    }
  },
  computed: {
    navigation() {
      const nextPiece = getNextPieceBySlug( this.slug )

      return {
        title: nextPiece.title,
        slug: nextPiece.slug,
        href: `/${ routes.PIECE }/${ nextPiece.slug }`
      }
    }
  },
  methods: {
    onClick() {
      this.navigateNext()
    },
    onMouseEnter() {
      if ( this.isHoverAnimationRunning ) return
      this.isHoverAnimationRunning = true
      clearTimeout( this.hoverAnimationTimer )
      this.hoverAnimationTimer = setTimeout( () => { this.isHoverAnimationRunning = false }, 800 )
    },
    navigateNext() {
      this.onNavigate()
      this.$router.push( {
        name: routes.PIECE,
        params: { slug: this.navigation.slug }
      } )
    }
  }
}
</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  .NavigationFooter {

    position: relative;

    width: 100%;
    height: calc( 100vh + 5vw );

    margin-top: calc( -10rem + -10vw );

    @include breakpoint( mobile ) {

      margin-top: calc( -5rem + -5vw );

    }

    > div {

      position: absolute;
      left: 0;
      top: 50%;

      width: 100%;
      height: 0;

      text-align: center;

    }

    p {

      margin: 0;

      color: rgba( $color-base, 0.45 );
      @include fontMedium();
      font-size: rem( 15 );
      letter-spacing: 0.01em;
      text-transform: uppercase;

      transform: translate3d( 0, -50%, 0 );

      span {

        position: absolute;
        left: 0;
        bottom: 100%;

        display: block;
        width: 100%;
        padding: 0 10vw;

      }

      a {

        display: inline-block;
        margin: 0 10vw;

        @include fontBlack();
        color: $color-violet;
        font-size: 9vw;
        line-height: 1;
        text-decoration: none;

        transform: scale( 0.75 );

        strong {

          display: block;

        }

        &.is-animating-hover {

          color: $color-violet;

          strong {

            @keyframes link-hover {
              0% {
                transform: translate3d( 0, rem( 10 ), 0 );
                color: $color-black;
              }
              13% {
                color: $color-black;
              }
              13.1% {
                color: $color-violet;
              }
              100% {
                transform: none;
              }
            }

            animation: link-hover both 1.2s $ease-out-quart;

          }

        }

      }

    }

  }

</style>
