<template>
  <section class="Piece" :style="{ paddingTop: sectionPaddingTop }">
    <PieceTitle
      :title="piece.title"
      :year="piece.year"
      :isNavigatingToNext="isNavigatingToNext"
    />

    <figure class="mounted first" :style="{ marginBottom: firstMarginBottom }">
      <div class="wall">
        <transition name="pan" v-on:after-leave="onLeaveComplete">
          <div
            class="group"
            :class="{ 'is-shallow': $store.state.isScrollShallow }"
            :style="
              getMountedPieceDimensions(
                piece.aspect,
                piece.scale,
                viewportWidth,
                calcHeight
              )
            "
            v-show="isActiveOnStage"
          >
            <img
              v-bind="piece.previews[0].attr"
              class="piece"
              :alt="`${piece.title} showcase image 1`"
            />
            <MountedPieceShadow />
          </div>
        </transition>
      </div>
    </figure>

    <article class="narrative" v-if="piece.narrative">
      <div class="centered">
        <h2>{{ piece.narrative.headline }}</h2>
        <p>
          <span
            v-for="(line, index) in piece.narrative.description"
            :key="index"
            v-html="line"
          />
        </p>
      </div>
      <div class="showcase" v-if="piece.showcase">
        <figure
          v-for="(item, index) in piece.showcase"
          :key="index"
          class="mounted"
          :style="{
            height: getMountedPieceDimensions(
              item.aspect || piece.aspect,
              item.scale || piece.scale,
              viewportWidth,
              calcHeight
            ).height,
          }"
        >
          <div class="wall">
            <div
              class="group"
              :style="
                getMountedPieceDimensions(
                  item.aspect || piece.aspect,
                  item.scale || piece.scale,
                  viewportWidth,
                  calcHeight
                )
              "
            >
              <img
                v-bind="item.attr"
                class="piece"
                :alt="`${piece.title} showcase image ${index + 2}`"
                tabindex="0"
              />
              <MountedPieceShadow />
            </div>
          </div>
        </figure>
      </div>
    </article>

    <article class="process" v-if="piece.process">
      <div class="centered">
        <h3>{{ piece.process.headline }}</h3>
        <p>
          <span
            v-for="(line, index) in piece.process.description"
            :key="index"
            v-html="line"
          />
        </p>
      </div>
      <div class="showcase" v-if="piece.process.showcase">
        <figure
          v-for="(item, index) in piece.process.showcase"
          :key="index"
          class="mounted"
          :style="{
            height: getMountedPieceDimensions(
              item.aspect || piece.aspect,
              item.scale || piece.scale,
              viewportWidth,
              calcHeight
            ).height,
          }"
        >
          <div class="wall">
            <div
              class="group"
              :style="
                getMountedPieceDimensions(
                  item.aspect || piece.aspect,
                  item.scale || piece.scale,
                  viewportWidth,
                  calcHeight
                )
              "
            >
              <img
                v-bind="item.attr"
                class="piece"
                :alt="`${piece.title} process image ${index + 2}`"
                tabindex="0"
              />
              <MountedPieceShadow />
            </div>
          </div>
        </figure>
      </div>
      <figure class="inspect" v-if="piece.process.inspect">
        <img
          v-if="$store.state.isMobile"
          v-bind="piece.process.inspect.attrPortrait"
          :alt="`${piece.title} close-up image`"
          tabindex="0"
        />
        <img
          v-else
          v-bind="piece.process.inspect.attr"
          :alt="`${piece.title} close-up image`"
          tabindex="0"
        />
      </figure>
    </article>

    <article class="gallery" v-if="piece.gallery">
      <div class="centered">
        <h3 v-if="piece.gallery.headline">{{ piece.gallery.headline }}</h3>
        <p v-if="piece.gallery.description">
          <span
            v-for="(line, index) in piece.gallery.description"
            :key="index"
            v-html="line"
          />
        </p>
      </div>
      <div
        class="section"
        v-for="(section, index) in piece.gallery.sections"
        :key="index"
      >
        <div class="centered">
          <h4>{{ section.name }}</h4>
          <p>{{ section.description }}</p>
        </div>
        <div class="items" v-if="section.items">
          <figure
            v-for="(item, index) in section.items"
            :key="index"
            class="item"
          >
            <img
              v-bind="item.attr"
              class="piece"
              :alt="`${piece.title} process image ${index + 2}`"
              tabindex="0"
            />
            <MountedPieceShadow />
          </figure>
        </div>
        <div class="videos" v-if="section.videos">
          <figure class="video">
            <video autoplay loop playsinline muted>
              <source :src="section.videos.webm" type="video/webm" />
              <source :src="section.videos.mp4" type="video/mp4" />
            </video>
            <MountedPieceShadow />
          </figure>
        </div>
      </div>
    </article>

    <article class="sales" v-if="piece.sales">
      <div class="centered">
        <h3>Available for purchase</h3>
        <p>
          <span v-for="(line, index) in piece.sales.pitch" :key="index">
            {{ line }}
          </span>
        </p>
        <figure
          v-for="(item, index) in piece.sales.specimens"
          :key="index"
          class="mounted"
          :style="{
            height: getMountedPieceDimensions(
              item.aspect || piece.aspect,
              item.scale || piece.scale,
              viewportWidth,
              calcHeight
            ).height,
          }"
        >
          <div class="wall">
            <div
              class="group"
              :style="
                getMountedPieceDimensions(
                  item.aspect || piece.aspect,
                  item.scale || piece.scale,
                  viewportWidth,
                  calcHeight
                )
              "
            >
              <img
                v-bind="item.attr"
                class="piece"
                :alt="`${piece.title} image for sale ${index + 1}`"
                tabindex="0"
              />
              <MountedPieceShadow />
            </div>
          </div>
        </figure>
        <hr />
        <div class="row">
          <div class="printing">
            <h5>Printing</h5>
            <ul>
              <li
                v-for="(line, index) in piece.sales.specs.printing"
                :key="index"
              >
                {{ line }}
              </li>
            </ul>
          </div>
          <hr class="conditional" />
          <div class="paper">
            <h5>Paper</h5>
            <ul>
              <li v-for="(line, index) in piece.sales.specs.paper" :key="index">
                {{ line }}
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="dimensions">
            <h5>Size</h5>
            <ul>
              <li
                v-for="(line, index) in piece.sales.specs.dimensions"
                :key="index"
              >
                {{ line }}
              </li>
            </ul>
          </div>
          <hr class="conditional" />
          <div class="price">
            <h4>{{ piece.sales.specs.price[0] }}<sub>NZD</sub></h4>
            <ul>
              <li
                v-for="(line, index) in piece.sales.specs.price.slice(1)"
                :key="index"
              >
                {{ line }}
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <p class="footnotes">
          Global international courier delivery is available.<br />
          Enquire at
          <a href="mailto:kellymilligan.art@gmail.com" target="_blank"
            >kellymilligan.art@gmail.com</a
          >
          to browse available editions.
        </p>
      </div>
    </article>

    <NavigationFooter :slug="slug" :onNavigate="onFooterNavigate" />
  </section>
</template>

<script>
import * as Detect from '@/utils/detect'

import routes from '@/data/routes'
import collection from '@/data/collection'

import PieceTitle from '@/components/PieceTitle.vue'
import MountedPieceShadow from '@/components/MountedPieceShadow'
import NavigationFooter from '@/components/NavigationFooter'

import { getMountedPieceDimensions } from '@/mixins'

export default {
  name: 'Piece',
  mixins: [getMountedPieceDimensions],
  components: {
    PieceTitle,
    MountedPieceShadow,
    NavigationFooter,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    scrollToTop: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isFirefox: Detect.firefox(),
      isSafari: Detect.safari(),
      piece: collection.filter((piece) => piece.slug === this.slug)[0],
      onLeaveComplete: () => {},
      isNavigatingToNext: false,
      stageWarningsSnoozed: true,
    }
  },
  computed: {
    viewportWidth() {
      return this.$store.state.viewportWidth
    },
    viewportHeight() {
      return this.$store.state.viewportHeight
    },
    viewportHeightAtInit() {
      return this.$store.state.viewportHeightAtInit
    },
    calcHeight() {
      return this.$store.state.isMobile
        ? this.viewportHeightAtInit
        : this.viewportHeight
    },
    sectionPaddingTop() {
      const { h } = this.getMountedPieceDimensions(
        this.piece.aspect,
        this.piece.scale,
        this.viewportWidth,
        this.calcHeight
      )
      const vh = this.$store.state.isMobile ? 'var( --vhAtInit )' : '100vh'
      return `calc( ( ${vh} ) + ${h / 2}vw )`
      // return `calc( 100vh + ${ h / 2 }vw )`
    },
    firstMarginBottom() {
      const { height } = this.getMountedPieceDimensions(
        this.piece.aspect,
        this.piece.scale,
        this.viewportWidth,
        this.calcHeight
      )
      const vh = this.$store.state.isMobile ? 'var( --vhAtInit )' : '100vh'
      return `calc( ( ${vh} ) - ( ${height} ) )`
      // return `calc( 100vh - ( ${ height } ) )`
    },
  },
  created() {
    this.$store.commit('setActiveGroupIndexBySlug', this.piece.slug)
  },
  methods: {
    stageLeave() {
      const callback = new Promise((resolve) => {
        this.onLeaveComplete = resolve
      })

      // Immediately navigate when moving to About page
      if (
        this.$route.name === routes.ABOUT ||
        this.$route.name === routes.LEGAL
      ) {
        this.onLeaveComplete()
        return
      }

      if (
        (this.isFirefox || this.isSafari) &&
        this.$route.name === routes.HOME &&
        !this.$store.state.isScrollShallow
      ) {
        setTimeout(() => this.onLeaveComplete(), 700)
      }

      this.scrollToTop(false, !this.isNavigatingToNext)

      // Shortcircuit during transition when using NavigationFooter to navigate
      if (this.isNavigatingToNext) {
        setTimeout(() => this.onLeaveComplete(), 800)
      }

      return callback
    },
    stageAfterLeave() {
      /*
        Note: A bit hacky...
        When navigating between peices, the shallow scroll flag isn't updated
        on scrollToTop to prevent animation artifacts. Instead, it's set slightly
        later to ensure animation continuity if closing this piece after navigation.
      */
      if (this.isNavigatingToNext && this.$route.name === routes.PIECE) {
        setTimeout(() => this.$store.commit('setScrollShallow', true), 100)
      }

      this.isNavigatingToNext = false
    },
    stageAfterEnter() {
      this.$store.commit('setScrollLocked', false)

      setTimeout(() => this.$store.commit('setScrollShallow', true), 0)

      /*
        Defer an additional unlock, sometimes when navigating the scroll lock is
        called after this callback for some reason...
      */
      setTimeout(() => {
        this.$store.commit('setScrollLocked', false)
      }, 0)
    },
    onFooterNavigate() {
      this.isNavigatingToNext = true
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/core.scss';

/*
    Links within Piece description content are dynamically added with
    v-html, therefore the scoped styling below can't be applied to it.
  */
.Piece article p a {
  @include fontMedium();
  text-decoration: none;

  transition: color 400ms $ease-out-quart;

  &:hover {
    color: rgba($color-base, 0.75);
  }
}
</style>

<style scoped lang="scss">
@use 'sass:math';
@import '@/styles/core.scss';
@import '@/styles/components/mountedPiece.scss';

$enterDelay: 0.6s;
$scrollToTopTime: 1.6;
$scrollToTopTimeShallow: 0.1;

.Piece {
  overflow: hidden;

  position: relative;
  padding-top: 100vh; // overridden

  .mounted {
    position: relative;

    width: 100%;
    height: 100vh; // Overridden in JS for showcase pieces
    margin: calc(5rem + 5vw) 0;

    @include breakpoint(mobile) {
      height: var(--vhAtInit);
      margin: calc(2.5rem + 2.5vw) 0;
    }

    .wall {
      @include mountedPiece();
    }

    &.first {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;

      // Add top margin to element immediately following first mounted piece
      + * {
        margin-top: calc(10rem + 10vw);

        @include breakpoint(mobile) {
          margin-top: calc(5rem + 5vw);
        }
      }

      .wall {
        .group {
          @keyframes pan-in {
            0% {
              transform: translate3d(0, 0, 0) scale(0.995);
            }
            100% {
              transform: translate3d(0, 50vh, 0);
            }
          }

          @keyframes pan-out {
            0% {
              transform: translate3d(0, 50vh, 0);
            }
            100% {
              transform: translate3d(0, 0, 0) scale(0.995);
            }
          }

          @keyframes pan-in-mobile {
            0% {
              transform: translate3d(0, 0, 0) scale(0.995);
            }
            100% {
              transform: translate3d(0, var(--vhAtInitHalf), 0);
            }
          }

          @keyframes pan-out-mobile {
            0% {
              transform: translate3d(0, var(--vhAtInitHalf), 0);
            }
            100% {
              transform: translate3d(0, 0, 0) scale(0.995);
            }
          }

          transform: translate3d(0, 50vh, 0);

          &.pan-enter-active {
            animation: pan-in 2s $enterDelay backwards $ease-in-out-quart;
          }

          &.pan-leave-active {
            animation: pan-out 0.8s #{$scrollToTopTime + 0.25}s
              $ease-in-out-quart;
            &.is-shallow {
              animation-delay: #{$scrollToTopTimeShallow + 0.2}s;
            }
          }

          @include breakpoint(mobile) {
            transform: translate3d(0, var(--vhAtInitHalf), 0);

            &.pan-enter-active {
              animation-name: pan-in-mobile;
            }
            &.pan-leave-active {
              animation-name: pan-out-mobile;
            }
          }
        }
      }
    }
  }

  article {
    .centered {
      width: 50vw;
      max-width: 800px;
      margin: 0 auto;

      @include breakpoint(1024) {
        width: 60vw;
      }
      @include breakpoint(800) {
        width: 70vw;
      }
      @include breakpoint(mobile) {
        width: 100%;
        padding: 0 25px;
      }
    }

    h2,
    h3 {
      max-width: 300px;

      margin: 0 0 rem(23);

      @include fontBlack();
      font-size: rem(27);
      line-height: 1.3;
      letter-spacing: 0.01em;
      text-transform: uppercase;

      @include breakpoint(mobile) {
        max-width: 100%;
      }
    }

    h4 {
      margin: 0;

      @include fontBlack();
      font-size: rem(27);
      letter-spacing: 0.01em;
      text-transform: uppercase;

      sub {
        bottom: 0;
        margin-left: rem(5);

        font-size: rem(15);
        @include fontMedium();
        color: rgba($color-base, 0.45);
      }
    }

    h5 {
      margin: 0;

      @include fontMedium();
      font-size: rem(15);
      letter-spacing: 0.01em;
    }

    p,
    li {
      color: rgba($color-base, 0.45);
    }

    p {
      columns: 3 300px;
      column-gap: 60px;
      margin: 0;

      span {
        display: block;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    hr {
      display: block;
      width: 100%;
      height: 1px;
      margin: rem(40) 0;

      background: rgba(0, 0, 0, 0.1);
      border: 0;

      @include breakpoint(mobile) {
        margin: rem(20) 0;
      }

      &.conditional {
        display: none;

        @include breakpoint(mobile) {
          display: block;
        }
      }
    }
  }

  .narrative,
  .process,
  .gallery,
  .sales {
    margin: calc(10rem + 10vw) 0;

    @include breakpoint(mobile) {
      margin: calc(5rem + 5vw) 0;
    }
  }

  .process {
    .inspect {
      position: relative;

      width: 100%;
      padding-bottom: 40%;
      margin: calc(5rem + 5vw) 0;

      background: $color-white;

      @include breakpoint(mobile) {
        padding-bottom: 0;
        margin: calc(2.5rem + 2.5vw) 0;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;

        display: block;
        width: 100%;
        height: 100%;

        @include breakpoint(mobile) {
          position: relative;
          height: auto;
        }
      }
    }
  }

  .gallery {
    .section {
      margin-top: calc(5rem + 5vw);

      .items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1440px;
        margin: 0 auto;
      }

      .item {
        position: relative;
        width: 35%;
        padding-bottom: 50%;
        margin: calc(2.5rem + 2.5vw) 5% 0;

        &:nth-of-type(odd) {
          margin-right: 5%;
        }
        &:nth-of-type(even) {
          margin-left: 0;
        }

        @include breakpoint(mobile) {
          width: calc(100% - 100px);
          padding-bottom: calc((100vw - 100px) * 1.425);
          margin: 28px 0 0;
          &:nth-of-type(odd) {
            margin-right: 0;
          }
        }

        img {
          position: absolute;
          left: 0;
          top: 0;

          display: block;
          width: 100%;
          height: auto;
        }
      }

      .videos {
        display: flex;
        justify-content: center;
        max-width: 1440px;
        padding: 0 12.5%;
        margin: calc(2.5rem + 2.5vw) auto 0;

        @include breakpoint(mobile) {
          padding: 0 25px;
        }
      }

      .video {
        position: relative;
        width: 100%;
        padding-bottom: math.div(720, 1280) * 100%;

        video {
          position: absolute;
          left: 0;
          top: 0;

          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .sales {
    .row {
      display: flex;

      @include breakpoint(mobile) {
        display: block;
      }
    }

    .printing,
    .paper,
    .dimensions,
    .price {
      width: 50%;

      @include breakpoint(mobile) {
        width: 100%;
      }
    }

    .paper,
    .price {
      text-align: right;

      @include breakpoint(mobile) {
        text-align: left;
      }
    }

    .price {
      h4 {
        margin-top: -0.1em;

        @include breakpoint(mobile) {
          margin-top: 0;
          text-align: right;
        }
      }
    }

    .footnotes {
      columns: 1;
    }
  }
}
</style>
