<template>
  <div class="PieceTitle">
    <transition name="title" v-on:after-leave="onLeaveComplete">
      <h1
        v-if="isActiveOnStage"
        :class="{ 'is-shallow': $store.state.isScrollShallow }"
      >
        <span class="title">
          {{ title }}
          <span v-if="year" class="year">{{ year }}</span>
        </span>
      </h1>
    </transition>
  </div>
</template>

<script>
import * as Detect from "@/utils/detect";

import routes from "@/data/routes";

export default {
  name: "PieceTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    year: {
      type: String
    },
    isNavigatingToNext: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      isFirefox: Detect.firefox(),
      isSafari: Detect.safari(),
      // stageWarningsSnoozed: true,
      onLeaveComplete: () => {}
    };
  },
  methods: {
    stageLeave() {
      const callback = new Promise(resolve => {
        this.onLeaveComplete = resolve;
      });

      // Next tick as isNavigatingToNext is passed down after it's set in Piece.vue
      this.$nextTick(() => {
        // Immediately navigate when moving to About or Legal page
        if (
          this.$route.name === routes.ABOUT ||
          this.$route.name === routes.LEGAL
        ) {
          this.onLeaveComplete();
        }

        if (
          (this.isFirefox || this.isSafari) &&
          this.$route.name === routes.HOME &&
          !this.$store.state.isScrollShallow
        ) {
          setTimeout(() => this.onLeaveComplete(), 700);
        }

        // Shortcircuit during transition when using NavigationFooter to navigate
        if (this.isNavigatingToNext) {
          setTimeout(() => this.onLeaveComplete(), 800);
        }
      });

      return callback;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/core.scss";

$enterDelay: 0.7s;
$scrollToTopTime: 1.6;
$scrollToTopTimeShallow: 0.1;

.PieceTitle {
  position: absolute;
  left: 0;
  top: 50vh;

  text-align: center;
  width: 100vw;

  transform: translate3d(0%, -50%, 0);

  @include breakpoint(mobile) {
    top: var(--vhAtInitHalf);
  }

  h1 {
    width: 100%;
    padding: 0 10vw;
    margin: 0;

    @include fontBlack();
    color: $color-violet;
    font-size: 9vw;
    line-height: 1;
    text-transform: uppercase;

    transform-origin: center top;

    transform: translate3d(0, -15vh, 0);

    span.title {
      position: relative;
      display: block;
      transform: scale(0.75, 0.75);

      @include breakpoint(mobile) {
        transform: scale(1, 1) !important;
      }
    }

    span.year {
      position: absolute;
      left: 0;
      top: 100%;

      display: block;
      width: 100%;
      margin-top: rem(25);

      color: $color-black;
      font-size: 3vw;
    }

    @keyframes title-in-translate {
      from {
        transform: translate3d(0, 0, 0);
      }
      to {
        transform: translate3d(0, -15vh, 0);
      }
    }

    @keyframes title-in-scale {
      from {
        transform: scale(1, 1);
      }
      to {
        transform: scale(0.75, 0.75);
      }
    }

    @keyframes title-out {
      from {
        transform: translate3d(0, -15vh, 0);
      }
      to {
        transform: translate3d(0, calc(-15vh - 2.5vw), 0);
      }
    }

    &.title-enter-active {
      animation: title-in-translate 2s $enterDelay both $ease-in-out-quart;

      span.title {
        animation: title-in-scale 1s $enterDelay both $ease-in-out-cubic;

        @include breakpoint(mobile) {
          animation: none;
        }
      }
    }

    &.title-leave-active {
      animation: title-out 0.4s #{$scrollToTopTime + 0.05}s
        cubic-bezier(0.75, -0.5, 1, 0);

      &.is-shallow {
        animation-delay: #{$scrollToTopTimeShallow + 0.1}s;
      }

      span.title {
        animation: none;
      }
    }
  }
}
</style>
